import {useEnvironment} from '@wix/yoshi-flow-editor'
import {useEffect} from 'react'
import {usePrevious} from '../../../../../../commons/hooks/use-previous'
import {useAppActions, useAppSelector} from '../../../hooks/runtime'
import {isReservationUnavailable} from '../../../selectors/reservation'

export const useCheckoutUnavailableCheck = () => {
  const {isEditor} = useEnvironment()
  const {openCheckoutUnavailable} = useAppActions()
  const reservationUnavailable = useAppSelector(isReservationUnavailable)
  const prevReservationUnavailable = usePrevious(reservationUnavailable)
  useEffect(() => {
    if (prevReservationUnavailable !== reservationUnavailable && reservationUnavailable && !isEditor) {
      openCheckoutUnavailable()
    }
  }, [isEditor, reservationUnavailable, prevReservationUnavailable, openCheckoutUnavailable])
}
