import {ExperimentNames, hasSeatingPlan} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useExperiments, useTranslation} from '@wix/yoshi-flow-editor'
import React from 'react'
import {useAppActions, useAppSelector} from '../../../../hooks/runtime'
import {getEvent, isCheckoutAvailable, isCheckoutButtonDisabled} from '../../../../selectors/event'
import {FormEntry} from '../../../chunks/form-entry'
import {SeatingPlanModal} from '../../../chunks/seating-plan-modal'
import {RegistrationButtonSkin} from '../../../registration-buttons/registration-button-skin'
import {TotalPrice} from './total-price'

export const Footer = ({onTicketSelectionError}: {onTicketSelectionError: () => void}) => {
  const {isMobile} = useEnvironment()
  const checkoutAvailable = useAppSelector(isCheckoutAvailable)
  const checkoutButtonDisabled = useAppSelector(isCheckoutButtonDisabled)
  const withSeatingPlan = useAppSelector(state => hasSeatingPlan(getEvent(state)))
  const {checkout, openSeatingPlan} = useAppActions()
  const {t} = useTranslation()
  const {experiments} = useExperiments()

  const disabledButtonsViewerEnabled = experiments.enabled(ExperimentNames.DisabledButtonsViewer)

  const handleOnClick = () => {
    if (disabledButtonsViewerEnabled && !checkoutAvailable) {
      onTicketSelectionError()
      return
    }

    if (withSeatingPlan) {
      SeatingPlanModal.preload()
      return openSeatingPlan()
    }

    FormEntry.preload()
    return checkout()
  }

  return (
    <>
      {withSeatingPlan ? null : <TotalPrice />}
      {(!disabledButtonsViewerEnabled || !checkoutButtonDisabled) && (
        <RegistrationButtonSkin
          secondary={true}
          minWidth="100%"
          minHeight={isMobile ? undefined : '48px'}
          disabled={disabledButtonsViewerEnabled ? false : !checkoutAvailable}
          onClick={handleOnClick}
          dataHook={isMobile ? DH.CHECKOUT : DH.RESERVE_TICKETS_BUTTON}
          narrowerButton={isMobile}
          text={withSeatingPlan ? t('ticketsPicker_selectTickets') : t('checkout')}
        />
      )}
    </>
  )
}
