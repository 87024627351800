import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import c from '../../../../classnames.scss'
import {InvoiceBreakdown} from '../../../../invoice-breakdown'
import {InvoiceBreakdownSize} from '../../../../invoice-breakdown/interfaces'
import {TotalPriceProps} from './interfaces'
import s from './total-price.scss'

export const TotalPrice = ({soldOut, onSale}: TotalPriceProps) =>
  soldOut ? (
    <SoldOut />
  ) : onSale ? (
    <InvoiceBreakdown
      containerClass={classNames(s.container, c.textFontAndColor)}
      size={InvoiceBreakdownSize.big}
      preliminary={true}
    />
  ) : null

const SoldOut = () => {
  const {t} = useTranslation()

  return (
    <div className={c.textFontAndColor} data-hook="event-sold-out">
      <p className={s.soldOutLabel}>{t('ticketsPicker.soldOut')}</p>
    </div>
  )
}
