import {StatusAlert} from '@wix/wix-ui-icons-common'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {SectionNotification, SectionNotificationSize} from 'wix-ui-tpa'
import s from './error-section.scss'

export const ErrorSection = ({className}: {className: string}) => {
  const {t} = useTranslation()
  const {isRTL} = useEnvironment()

  return (
    <div className={classNames(className, {[s.rtlContainer]: isRTL})}>
      <SectionNotification type="error" size={SectionNotificationSize.regular}>
        <SectionNotification.Icon icon={<StatusAlert />} className={s.iconContainer} />
        <SectionNotification.Text className={s.textContainer}>
          {t('ticketsPicker_chooseTicket_errorMessage')}
        </SectionNotification.Text>
      </SectionNotification>
    </div>
  )
}
