import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import React from 'react'
import {Badge} from '../badge'
import {SoldOutBadgeProps} from './interfaces'

export const SoldOutBadge = ({ticket, className}: SoldOutBadgeProps) => {
  const {t} = useTranslation()

  if (ticket.limitPerCheckout) {
    return null
  }

  return (
    <Badge className={className} dataHook={DH.TICKET_SOLD_OUT}>
      {t('mobile.ticketsPicker.soldOut')}
    </Badge>
  )
}
