import {ChevronDown, ChevronUp} from '@wix/wix-ui-icons-common/on-stage'
import classNames from 'classnames'
import React, {PropsWithChildren} from 'react'
import {TextButton} from 'wix-ui-tpa/cssVars'
import {classes} from './expansion-button.st.css'
import {ExpansionButtonProps} from './interfaces'

export const ExpansionButton: React.FC<PropsWithChildren<ExpansionButtonProps>> = ({
  children,
  className,
  dataHook,
  expanded,
  onChange,
}) => (
  <TextButton
    data-hook={dataHook}
    aria-expanded={expanded}
    className={classNames(classes.root, className)}
    onClick={() => onChange(!expanded)}
  >
    {children}
    <div className={classes.chevron}>
      {expanded ? <ChevronUp className={classes.icon} /> : <ChevronDown className={classes.icon} />}
    </div>
  </TextButton>
)
