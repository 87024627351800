import {getGiftCardPaymentDetails} from '../../selectors/invoice'
import {getInvoice, getPreliminaryInvoice} from '../../selectors/placed-order'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {InvoiceBreakdownOwnProps, InvoiceBreakdownRuntimeProps} from './interfaces'
import {InvoiceBreakdown as Presentation} from './invoice-breakdown'

const mapRuntime = (
  {state}: DetailsPageAppProps,
  {preliminary}: InvoiceBreakdownOwnProps,
): InvoiceBreakdownRuntimeProps => ({
  invoice: preliminary ? getPreliminaryInvoice(state) : getInvoice(state),
  giftCardPaymentDetails: preliminary ? undefined : getGiftCardPaymentDetails(state),
})

export const InvoiceBreakdown = connect<InvoiceBreakdownOwnProps, InvoiceBreakdownRuntimeProps>(mapRuntime)(
  Presentation,
)
