import {shouldShowSaleEndsLabel} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Text} from 'wix-ui-tpa/cssVars'
import {SaleEndsProps} from './interfaces'
import {classes} from './sale-ends-label.st.css'

export const SaleEndsLabel = ({ticket, className}: SaleEndsProps) => {
  const {t} = useTranslation()
  const saleWillEnd = shouldShowSaleEndsLabel(ticket)
  const {isMobile} = useEnvironment()

  if (!saleWillEnd) {
    return null
  }

  const startDate = ticket.salePeriod.formattedSaleEndDate

  return (
    <Text
      tagName="div"
      className={classNames(classes.text, className, {
        [classes.alignment]: !isMobile,
      })}
      aria-label={`${t('ticketsPicker.saleEnds')}: ${startDate}`}
      data-hook={DH.TICKET_SALE_ENDS}
    >
      <p>{t('ticketsPicker.saleEnds')}</p>
      <p>{startDate}</p>
    </Text>
  )
}
