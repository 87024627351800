import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import React from 'react'
import {Text} from 'wix-ui-tpa/cssVars'
import {a11yTicketTypeId} from '../../a11y'
import {Labeled} from '../labeled'
import {classes} from './ticket-type.st.css'

export const TicketType = ({
  ticket,
  className,
}: {
  ticket: wix.events.ticketing.TicketDefinition
  className?: string
}) => {
  const {t} = useTranslation()
  const ticketId = a11yTicketTypeId(ticket)

  return (
    <Labeled label={t('viewer_ticketsPicker_ticketType')} labeledElementId={ticketId} className={className}>
      {({ariaLabeledBy}) => (
        <Text tagName="h3" id={ticketId} className={classes.name} data-hook={DH.NAME} aria-labelledby={ariaLabeledBy}>
          {ticket.name}
        </Text>
      )}
    </Labeled>
  )
}
