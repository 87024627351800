import {PricingOption} from '@wix/ambassador-wix-events-web/types'
import {
  getEventTicketLimitPerOrder,
  hasSeatingPlan,
  isRegistrationClosedVisible,
  saleStartedAndHasTickets,
} from '@wix/wix-events-commons-statics'
import React, {useCallback} from 'react'
import {useAppActions, useAppSelector} from '../../../../../hooks/runtime'
import {getEvent} from '../../../../../selectors/event'
import {getSelectedTicketQuantity, getSelectedTicketsQuantity} from '../../../../../selectors/selected-tickets'
import {Quantity} from '../../fields/quantity'

export const PricingOptionQuantity = ({
  ticket,
  pricingOption,
  className,
}: {
  ticket: wix.events.ticketing.TicketDefinition
  pricingOption: PricingOption
  className?: string
}) => {
  const event = useAppSelector(getEvent)
  const registrationClosed = isRegistrationClosedVisible(event)
  const withSeatingPlan = hasSeatingPlan(event)
  const maxTicketsPerReservation = getEventTicketLimitPerOrder(event)
  const totalSelectedQuantity = useAppSelector(state => getSelectedTicketsQuantity(state))
  const selectedPricingOptionQuantity = useAppSelector(state =>
    getSelectedTicketQuantity(state, ticket.id, pricingOption.id),
  )
  const selectedWholeTicketQuantity = useAppSelector(state => getSelectedTicketQuantity(state, ticket.id))

  const availableTicketsQuantity = maxTicketsPerReservation - totalSelectedQuantity
  const ticketLimit = ticket.limitPerCheckout - selectedWholeTicketQuantity + selectedPricingOptionQuantity
  const orderLimit = availableTicketsQuantity + selectedPricingOptionQuantity
  const max = Math.min(orderLimit, ticketLimit)

  const readonly = !saleStartedAndHasTickets(ticket) || registrationClosed || withSeatingPlan

  const {selectTicket} = useAppActions()

  const onSelectCount = useCallback((count: number) => {
    selectTicket({ticketId: ticket.id, count, pricingOptionId: pricingOption.id})
  }, [])

  if (readonly) {
    return null
  }

  return (
    <div className={className}>
      <Quantity max={max} value={selectedPricingOptionQuantity} onSelectCount={onSelectCount} />
    </div>
  )
}
