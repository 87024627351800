import {getCheckoutSummary, getPaidPlanPercentDiscount, getTaxLabel} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import React from 'react'
import {useCurrencyFormatter} from '../../../../../commons/hooks/currency'
import {getLocale} from '../../../../../commons/selectors/environment'
import {useAppSelector} from '../../hooks/runtime'
import {SummaryDivider} from '../summary/summary-divider'
import {InvoiceBreakdownProps, InvoiceBreakdownSize} from './interfaces'
import s from './invoice-breakdown.scss'
import {PriceItem} from './price-item'
import {PriceItemSize} from './price-item/interfaces'

export const InvoiceBreakdown = ({
  invoice,
  giftCardPaymentDetails,
  containerClass = '',
  divider,
  size = InvoiceBreakdownSize.small,
}: InvoiceBreakdownProps) => {
  const {t} = useTranslation()
  const locale = useAppSelector(getLocale)
  const {getFormattedMoney} = useCurrencyFormatter(locale)
  const {subtotal, tax, addedFee, buyerTotal, discount, paidPlanDiscount, giftCards} = getCheckoutSummary(
    invoice,
    getFormattedMoney,
    giftCardPaymentDetails,
  )
  const big = size === InvoiceBreakdownSize.big
  const textSize: PriceItemSize = big ? PriceItemSize.medium : PriceItemSize.small
  const totalSize: PriceItemSize = big ? PriceItemSize.extraBig : PriceItemSize.big

  return (
    <div className={`${s.container} ${containerClass}`} data-hook={DH.INVOICE_BREAKDOWN}>
      {subtotal && (
        <>
          <div className={s.subtotal}>
            <PriceItem price={subtotal} label={t('ticketsPicker.subtotal')} hook={DH.SUBTOTAL} size={textSize} />
            <PriceItem
              price={paidPlanDiscount}
              label={t('ticketsThankYou.paidPlan', {
                discount: getPaidPlanPercentDiscount(invoice),
              })}
              hook={DH.PAID_PLAN_DISCOUNT_PRICE}
              size={textSize}
            />
            <PriceItem price={discount} label={t('ticketsThankYou.coupon')} hook={DH.DISCOUNT_PRICE} size={textSize} />
            <PriceItem price={tax} label={getTaxLabel(invoice)} hook={DH.TAX} size={textSize} />
            <PriceItem price={addedFee} label={t('checkout_serviceFee')} hook={DH.WIX_FEE} size={textSize} />
            {giftCards?.map((giftCard, index) => (
              <PriceItem
                key={index}
                price={`-${giftCard.amount}`}
                label={t('checkout_giftCard')}
                hook={DH.GIFT_CARD(index)}
                size={textSize}
              />
            ))}
          </div>
          {divider ?? <SummaryDivider compact />}
        </>
      )}
      <PriceItem price={buyerTotal} label={t('mobile.ticketsPicker.total')} hook={DH.TOTAL_PRICE} size={totalSize} />
    </div>
  )
}
