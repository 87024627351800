import {
  TicketWithFormattedSalePeriod,
  hasPricingOptions,
  isDonationTicketDefinition,
  isPricingOptionsWithSamePrice,
} from '@wix/wix-events-commons-statics'
import {useTranslation} from '@wix/yoshi-flow-editor'
import React from 'react'
import {useCurrencyFormatter} from '../../../../../../../../commons/hooks/currency'
import {getLocale} from '../../../../../../../../commons/selectors/environment'
import {DONATION_ERROR} from '../../../../../constants/donation-error'
import {useAppActions, useAppSelector} from '../../../../../hooks/runtime'
import {getPricingOptionsRangeLabel} from '../../../../../selectors/seating/place'
import {getSelectedTicketDonation, getSelectedTicketDonationError} from '../../../../../selectors/selected-tickets'
import {isDonationInputVisible} from '../../../../../selectors/tickets-picker'
import {checkDonationError} from '../../../../../services/donations'
import {Donation} from '../donation'
import {Fees} from '../fees'
import {GoesOnSaleLabel} from '../goes-on-sale-label'
import {Price} from '../price'
import s from './ticket-price.scss'

interface TicketPriceProps {
  ticket: TicketWithFormattedSalePeriod
  className?: string
}

export const TicketPrice = ({className, ticket}: TicketPriceProps) => {
  const {t} = useTranslation()
  const locale = useAppSelector(getLocale)
  const {getFormattedMoney} = useCurrencyFormatter(locale)

  const donation = useAppSelector(state => getSelectedTicketDonation(state, ticket.id))
  const donationError = useAppSelector(state => getSelectedTicketDonationError(state, ticket.id))
  const donationInputVisible = useAppSelector(state => isDonationInputVisible(state, ticket))
  const {changeTicketDonation, setDonationError} = useAppActions()

  const donationTicket = isDonationTicketDefinition(ticket)
  const pricingOptionsTicket = hasPricingOptions(ticket)

  let price: string
  if (donationTicket) {
    price = t('tickets.donation')
  } else if (pricingOptionsTicket) {
    if (isPricingOptionsWithSamePrice(ticket)) {
      price = ''
    } else {
      price = getPricingOptionsRangeLabel(ticket, t, getFormattedMoney)
    }
  } else {
    price = getFormattedMoney(ticket.price)
  }

  const onDonationChange = (newDonation: string) => {
    changeTicketDonation({ticketId: ticket.id, donation: newDonation})
  }

  const onDonationBlur = () => {
    const error = checkDonationError(ticket, donation, {excludeErrors: [DONATION_ERROR.EMPTY_DONATION]})
    if (error) {
      setDonationError({ticketId: ticket.id, error})
    }
  }

  return (
    <div className={className}>
      {donationInputVisible ? (
        <Donation
          ticket={ticket}
          value={donation}
          onChange={onDonationChange}
          onBlur={onDonationBlur}
          error={donationError}
        />
      ) : (
        <Price label={t('ticketsThankYou.price')} id={ticket.id} price={price} />
      )}
      {!pricingOptionsTicket ? <Fees ticket={ticket} overridePrice={donation} /> : null}
      <GoesOnSaleLabel ticket={ticket} className={s.goesOnSaleLabel} />
    </div>
  )
}
