import {
  getEventTicketLimitPerOrder,
  hasPricingOptions,
  hasSeatingPlan,
  isRegistrationClosedVisible,
  saleStartedAndHasTickets,
} from '@wix/wix-events-commons-statics'
import React, {useCallback} from 'react'
import {useAppActions, useAppSelector} from '../../../../../hooks/runtime'
import {getEvent} from '../../../../../selectors/event'
import {getSelectedTicketQuantity, getSelectedTicketsQuantity} from '../../../../../selectors/selected-tickets'
import {Quantity} from '../quantity'

export const TicketQuantity = ({
  ticket,
  className,
  hideErrorMessage,
}: {
  ticket: wix.events.ticketing.TicketDefinition
  className?: string
  hideErrorMessage: () => void
}) => {
  const event = useAppSelector(getEvent)
  const maxTicketsPerReservation = getEventTicketLimitPerOrder(event)
  const registrationClosed = isRegistrationClosedVisible(event)
  const withSeatingPlan = hasSeatingPlan(event)
  const saleStarted = saleStartedAndHasTickets(ticket)
  const withPricingOptions = hasPricingOptions(ticket)
  const {limitPerCheckout, id} = ticket
  const totalSelectedQuantity = useAppSelector(state => getSelectedTicketsQuantity(state))
  const selectedQuantity = useAppSelector(state => getSelectedTicketQuantity(state, id))

  const {selectTicket} = useAppActions()

  const onSelectCount = useCallback((count: number) => {
    selectTicket({ticketId: id, count})
    if (count > 0) {
      hideErrorMessage()
    }
  }, [])

  if (!saleStarted || withPricingOptions || withSeatingPlan || registrationClosed) {
    return null
  }

  const availableTicketsQuantity = maxTicketsPerReservation - totalSelectedQuantity
  const max = Math.min(availableTicketsQuantity + selectedQuantity, limitPerCheckout)

  return (
    <div className={className}>
      <Quantity max={max} value={selectedQuantity} onSelectCount={onSelectCount} />
    </div>
  )
}
